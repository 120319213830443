import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Peter Bull" subtitle="Director of Technology" mdxType="AuthorBlock">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "679px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/17d5be8dc3ad31261c0b38e68915c028/30e56/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAwADAAAAAAAAAAAAAAAAAAIDAQQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwQC/9oADAMBAAIQAxAAAAHWd33nmAUBXMFkpcmIf//EAB0QAAICAQUAAAAAAAAAAAAAAAABAxIRAgQQEyH/2gAIAQEAAQUChj7HLDRG1ZM1UvQ1vPD8Zk//xAAYEQACAwAAAAAAAAAAAAAAAAABEgACEP/aAAgBAwEBPwGoCak//8QAGREAAQUAAAAAAAAAAAAAAAAAAQACAxAS/9oACAECAQE/AXE7sSL/xAAaEAACAgMAAAAAAAAAAAAAAAAAERAhAQIg/9oACAEBAAY/AhtxtgVxQ3z/AP/EAB0QAAEEAwEBAAAAAAAAAAAAAAEAESExEEGBUXH/2gAIAQEAAT8hKSWA2ogx8wEEmxafgNNm8OKEKJgktxast4u1/9oADAMBAAIAAwAAABDvwkP/xAAZEQEAAgMAAAAAAAAAAAAAAAABACEQEWH/2gAIAQMBAT8QU17go1ELU//EABgRAQADAQAAAAAAAAAAAAAAAAEAEBFR/9oACAECAQE/EBE3lKrsQMZ//8QAIBABAQACAQMFAAAAAAAAAAAAAREAMSEQQVFhgZHB8P/aAAgBAQABPxAWCWqLPbFSM3YehsIEz25n3jFJAbNt9DU2kOb5uORKM337TL6mBNCn5cHibIvlcg4fg5//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/17d5be8dc3ad31261c0b38e68915c028/30e56/01.jpg",
              "srcSet": ["/static/17d5be8dc3ad31261c0b38e68915c028/f93b5/01.jpg 300w", "/static/17d5be8dc3ad31261c0b38e68915c028/b4294/01.jpg 600w", "/static/17d5be8dc3ad31261c0b38e68915c028/30e56/01.jpg 679w"],
              "sizes": "(max-width: 679px) 100vw, 679px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`The 2020 / 21 school year saw a strong focus on the use of IT resources across both administrative and classroom domains. In part this was driven by necessary shifts to online and remote work and learning.`}</p>
    </blockquote>
    <p>{`Schoolwide licensing for Zoom video conferencing helped enable remote work opportunities, and ensured teaching and learning could continue synchronously wherever possible during covid-related lockdowns. Additionally, licensing of Adobe Creative Cloud tools was provided to all staff and faculty and to students in grades six through twelve. Adobe Spark was provided schoolwide. These creative tools provided the conditions for additional creative opportunities for faculty to create dynamic learning experiences in classes.`}</p>
    <p>{`The IT team was expanded by adding a web development team member to enable the creation and development of web and mobile applications. The initial project was the building of an internal social network - Belong - to allow new hires to connect with their social buddies and members of the transition team to ease the transition to Kyiv and PSI.`}</p>
    <p>{`The Toddle Learning Management System was trialled in a number of PYP classes for unit planning. It will be part of a phased rollout in the 21 / 22 school year.`}</p>
    <p><strong parentName="p">{`A number of other developments occurred throughout 20 / 21 school year, including:`}</strong></p>
    <ul>
      <li parentName="ul">{`Wifi upgrade (20 new access points)`}</li>
      <li parentName="ul">{`New admissions portal (including payment gateway)`}</li>
      <li parentName="ul">{`Purchase of iPads (with keyboard/trackpad covers) for 2nd and 3rd grades`}</li>
      <li parentName="ul">{`Chromebook fleet retired from service`}</li>
      <li parentName="ul">{`Google Classroom introduced as common LMS for MYP / DP`}</li>
      <li parentName="ul">{`Series of videos produced in-house in collaboration with communications team`}</li>
      <li parentName="ul">{`Cybervulnerability assessment - remedial actions undertaken to enhance digital security of systems and infrastructure`}</li>
      <li parentName="ul">{`Exam.net set up for mock exams during remote learning`}</li>
      <li parentName="ul">{`Introduction of Veracross employment module`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      